<template>
  <v-container class="container-content-base">
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <v-layout column>
            <v-layout column>
              <label class="mr-3 label-dense">
                {{ $t('name_prices') }}
                <span class="red--text">{{ $t("required") }}</span>
              </label>
              <v-layout>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="name"
                  :rules="requiredRules"
                  class="w-fluid"
                >
                  <v-text-field
                    v-model="name"
                    outlined
                    dense
                    :maxlength="maximumSmallText"
                    :placeholder="$t('place_holders.name_prices')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <v-layout class="d-flex mt-3">
              <v-layout column style="flex: 50">
                <label class="mr-3 label-dense">
                  {{ $t("ship_service") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  :rules="requiredRules"
                >
                  <v-select
                    v-model="serviceSelect"
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="description"
                    item-value="id"
                    :placeholder="$t('place_holders.ship_service')"
                    :error-messages="errors"
                    :items="lstService"
                    @change="getSales"
                  />
                </validation-provider>
              </v-layout>
              <div style="flex: 50" class="d-flex justify-space-between ml-2">
                <div class="d-flex flex-column mr-2" style="flex: 1">
                  <label class="mr-3 label-dense">
                    {{ $t("account_link") }}
                    <span class="red--text">{{ $t("required") }}</span>
                  </label>
                  <div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="textRequired"
                      vid="name"
                      :rules="requiredRules"
                    >
                      <v-select
                        v-model="accountSelect"
                        outlined
                        dense
                        class="rounded-lg"
                        item-value="id"
                        :item-disabled="disableItem"
                        :items="lstAccount"
                        :placeholder="$t('Chọn tài khoản liên kết')"
                        :error-messages="errors"
                        @change="showDataAccount"
                      >
                        <template v-slot:selection="{ item }">
                          {{ getTextTruncate(item.username, 20) }}
                        </template>
                        <template v-slot:item="{ item }">
                          <v-layout column>
                            <div>
                              {{ item.username }}
                            </div>
                            <span class="mb-2" v-html="getText(item.priceGroupName)" />
                          </v-layout>
                        </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
                <div v-if="Disabled" class="d-flex flex-column" style="flex: 1;">
                  <label class="mr-3 label-dense">
                    {{ $t("shipping_method") }}
                  </label>
                  <v-text-field
                    v-model="dataMethod"
                    outlined
                    readonly
                  />
                </div>
              </div>
            </v-layout>
            <v-layout class="border-primary rounded px-2 py-3">
              <v-layout column>
                <table class="table-price-list">
                  <HeaderPricePartner />
                  <tbody>
                    <InputPricePartner
                      v-for="(item, index) in lstPrice"
                      :key="item.vId"
                      :item="item"
                      :idx="index"
                      :on-blur-weight="onBlurWeight"
                      :on-delete-item="onDeleteItem"
                    />
                  </tbody>
                  <tfoot>
                    <v-icon
                      class="ml-4"
                      color="primary"
                      size="25"
                      @click="addItem(false, false)"
                    >
                      mdi-plus-circle-outline
                    </v-icon>
                  </tfoot>
                </table>
              </v-layout>
            </v-layout>
            <v-layout justify-end class="mt-3">
              <v-btn
                class="mr-3"
                @click="cancelCreate"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                :disabled="invalid || invalidWeight || disabledBtn"
              >
                {{ $t("save_changes") }}
              </v-btn>
            </v-layout>
          </v-layout>
        </v-form>
      </validation-observer>
      <!-- Dialog loading -->
      <dialog-loading :is-show="showLoading" :message="$t('processing')" />
      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />
      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />

    </v-layout>
  </v-container>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import constants from '@/constants'
import HeaderPricePartner from '@/views/table_price/components/HeaderPricePartner'
import InputPricePartner from '@/views/table_price/components/InputPricePartner'
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import { StorageService } from '@/services/storageService'
import { StringUtils } from '@/helpers/stringUtils'
import { PricesService } from '@/services/pricesService'
import { PartnerService } from '@/services/partnerService'
import routePaths from '@/router/routePaths'
import mixinPricePartner from '@/views/table_price/mixins/mixinPricePartner'
export default {
  components: {
    Breadscrumbs,
    HeaderPricePartner,
    InputPricePartner,
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried
  },
  mixins: [
    mixinPricePartner
  ],
  data() {
    return {
      partnerId: null,
      partnerName: null,
      customerType: constants.customerType.shop,
      type: constants.pricesType.standard,
      name: '',
      maximumSmallText: constants.maximumSmallText,
      requiredRules: {
        required: true
      },
      groupName: null,
      items: [],
      accountSelect: '',
      lstAccount: [],
      dataMethod: '',
      showMethodShipping: false,
      showServiceShipping: false,
      valueMethod: '',
      detailId: this.$route.query.detailId,
      lstDetail: [],
      breadcrumbs: [
        {
          text: this.$t("partner_list"),
          disabled: false,
          isActive: false,
          href: ''
        },
        {
          text: "",
          disabled: false,
          isActive: false,
          href: ''
        },
        {
          text: '',
          disabled: true,
          isActive: true,
        }
      ],
      valueService: null,
      lstService: [],
      serviceSelect: null,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
      disabledBtn: false
    }
  },
  computed: {
    Disabled() {
      return this.partnerId === constants.partnerGroup.ghtk ? this.showMethodShipping : !this.showMethodShipping
    },
  },
  created() {
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") || this.currentPoId !== 2) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
    this.partnerId = parseInt(this.$route.query.id)
    this.groupName = this.$route.query.groupName
    this.breadcrumbs[0].href = routePaths.PARTNER_LIST
    this.breadcrumbs[1].href = `${routePaths.PARTNER_PRICES_LIST}?id=${this.partnerId}`
  },
  async beforeMount() {
    await this.initData()
    await this.getSales()
    this.showDataAccount()
    this.onChangeGetServiceAll()
  },
  methods: {
    // danh sach tai khoan lien ket
    async getSales() {
      this.accountSelect = null
      this.showLoading = true
      const pars = {
        parnerId: this.partnerId,
        serviceId: this.serviceSelect,
      }
      const { status, data } = await PartnerService.getSales(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.lstAccount = data
        this.lstAccount.forEach((it) => {
          if (it.status === 1) {
            this.lstAccount.push(it);
          }
          if (it.id === this.lstDetail.partnerSaleId) {
            this.accountSelect = this.lstDetail.partnerSaleId
          }
        })
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
      }
    },
    async onChangeGetServiceAll() {
      try {
        const response = await PartnerService.getServiceAll()
        this.lstService = response.data.filter((item) => item.deliveryPartnerId === this.partnerId)
      } catch (error) {
        console.log(error);
      }
    },
    getText(item) {
      if (item) {
        return `<span style="color: red; font-size: 12px"> Đã gắn bảng giá </span>`
      }
    },
    disableItem(item) {
      if (item.priceGroupName) {
        return true;
      }
      return false;
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    showDataAccount() {
      if (this.partnerId === '1') {
        this.showMethodShipping = true;
      }
      this.showServiceShipping = true;
      this.items.forEach((val) => {
        if (val.id === this.accountSelect) {
          this.dataMethod = this.getTextTransportType(val.transportType)
          this.valueMethod = val.transportType
          this.valueService = val.serviceId
        }
      })
    },
    getTextTransportType(val) {
      return val === constants.transportType.ship
        ? this.$t('shipper_pickup')
        : this.$t('customer_bring_order')
    },
    getTextService(val) {
      if (val === constants.servicesShipping.normal) {
        return this.$t('normal')
      } else if (val === constants.servicesShipping.express) {
        return this.$t('fast')
      } else if (val === constants.servicesShipping.standard) {
        return this.$t('normal')
      } else {
        return this.$t('over')
      }
    },
    async initData() {
      const results = await this.getDetailPriceGroup()
      if (results.adminPriceDtos !== null && results.adminPriceDtos.length > 0) {
        this.isCreated = true
        this.name = results.name
        this.dataMethod = this.getTextTransportType(results.shippingType)
        this.serviceSelect = results.serviceId
        this.lstPriceOrigin = results
        this.lstPriceOrigin = results.adminPriceDtos
        await this.parseToLocalPrices(results.adminPriceDtos)
        this.autoIncrease = this.getHighestPriceId() + 1
        this.checkInvalidWeight()
        this.bindMaxWeight()
      } else if (!this.isCreated) {
        this.isCreated = false
        this.initItem()
      }
      if (this.partnerId === constants.partnerGroup.ninjaVan) {
        this.breadcrumbs[1].text = "table-njv";
        this.breadcrumbs[2].text = "edit-partner-price-njv";
      } else {
        this.breadcrumbs[1].text = "table-ghtk";
        this.breadcrumbs[2].text = "edit-partner-price-ghtk";
      }
    },
    async getDetailPriceGroup() {
      this.showLoading = true
      const { status, data } = await PricesService.getDetailPriceGroup(this.detailId)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.lstDetail = data
        return data
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      }
      return null
    },
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid && !this.invalidWeight) {
        this.updatePrices()
      }
    },
    cancelCreate() {
      this.name = '';
      this.accountSelect = ''
      this.$router.push(
        {
          path: routePaths.PARTNER_PRICES_LIST,
          query: {
            id: this.$route.query.id
          }
        }
      )
    },
    async updatePrices() {
      this.showLoading = true
      this.disabledBtn = true
      const pars = {
        name: this.name.trim(),
        partnerSaleId: this.accountSelect,
        serviceId: this.serviceSelect,
        poId: StorageService.getPostOfficeId(),
        partnerId: this.partnerId,
        adminPriceDtos: this.getRequestPars()
      }
      const { status, data } = await PricesService.adminUpdate(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t('success_saved_info')
        })
        setTimeout(() => {
          this.$router.push(
            {
              path: routePaths.PARTNER_PRICES_LIST,
              query: {
                id: this.$route.query.id
              }
            }
          )
        }, constants.timeOut)
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
        this.disabledBtn = false
      }
    },
    getRequestPars() {
      const rqPars = []
      if (this.lenLstPrice > 0) {
        const arrPrice = this.sortPricesByWeight()
        arrPrice.forEach((i, index) => {
          var fromWeight = 0
          if (index > 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 1].weight)
          } else if (index === 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 1].weight)
          }
          const iOrigin = this.findItemOriginById(i.vId)
          const item = this.mappingRequestItem(i, fromWeight, iOrigin)
          rqPars.push(item)
        })

        if (this.lstDeleteOrigin.length > 0) {
          const arrDel = this.lstDeleteOrigin
          arrDel.forEach((item) => {
            item.status = constants.status.del
            item.hasUpdate = true
            rqPars.push(item)
          })
        }
      }

      return rqPars
    },
    mappingRequestItem(i, fromWeight, iOrigin) {
      const item = {}
      item['partnerId'] = this.partnerId
      item['serviceId'] = this.serviceSelect
      item['poId'] = StorageService.getPostOfficeId()
      item['type'] = this.type
      item['transportType'] = this.valueMethod
      item['fromWeight'] = fromWeight
      item['toWeight'] = i.isMax ? null : StringUtils.numStrToFloat(i.weight)
      item['intracityPrice'] = StringUtils.numStrToFloat(i.intracityPrice)
      item['intracityAccumulatePrice'] = StringUtils.numStrToFloat(
        i.intracityAccumulatePrice
      )
      item['intrazonePrice'] = StringUtils.numStrToFloat(i.intrazonePrice)
      item['intrazoneAccumulatePrice'] = StringUtils.numStrToFloat(
        i.intrazoneAccumulatePrice
      )
      item['interzonePrice'] = StringUtils.numStrToFloat(i.interzonePrice)
      item['interzoneAccumulatePrice'] = StringUtils.numStrToFloat(
        i.interzoneAccumulatePrice
      )
      item['status'] = i.status
      if (
        typeof iOrigin !== constants.undefined &&
        iOrigin !== null &&
        iOrigin.id > 0
      ) {
        item['id'] = iOrigin.id
        item['pricesGroupId'] = iOrigin.pricesGroupId
        item['hasUpdate'] = this.checkItemChange(iOrigin, item, i.isMax)
      } else {
        item['hasUpdate'] = false
      }

      return item
    },
  }
}
</script>

<style src="@/styles/createPricePartner.scss" lang="scss">
::v-deep(.v-text-field .v-input__control .v-input__slot) {
  min-height: 40px !important;
}
</style>

